.root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #e8e7f3;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 5rem;
  border: 1px solid #cecece;
}

.body {
  flex: 1;
  display: grid;
  place-items: center;

  > div {
    border-radius: 16px;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 1.5rem 3rem;
  }
}
